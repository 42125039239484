"use client";

import PropTypes from "prop-types";
// @mui
import Box from "@mui/material/Box";
// routes
import { usePathname } from "src/routes/hooks";
//
import Footer from "./footer";
import Header from "./header";
import { useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function MainLayout({ children }) {
	const pathname = usePathname();

	const isHome = pathname === "/";
	const theme=useTheme();
	return (
		<Box sx={{ display: "flex", flexDirection: "column", height: 1,background:
        theme.palette.mode === "light"
        	? "linear-gradient(60.85deg, #f7f8f9 6.4%, #eceffe 77.52%, #f4f2fd 99.12%)"
        	: theme.palette.background.default, }}>
			<Header />

			<Box
				component="main"
				sx={{
					flexGrow: 1,
					...(!isHome && {
						pt: { xs: 8, md: 10 },
					}),
				}}
			>
				{children}
			</Box>

			<Footer />
		</Box>
	);
}

MainLayout.propTypes = {
	children: PropTypes.node,
};
