import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
// @mui
import Collapse from "@mui/material/Collapse";
// routes
import { usePathname } from "next/navigation";
import { useActiveLink } from "src/routes/hooks";
//
import NavItem from "./nav-item";

// ----------------------------------------------------------------------

export default function NavList({ data, depth, hasChild, config, handleClick, selectedItem }) {
	const pathname = usePathname();

	const active = useActiveLink(data.path, hasChild);

	const externalLink = data.path.includes("http");

	const [open, setOpen] = useState(active);

	useEffect(() => {
		if (!active) {
			handleClose();
		}
		
	}, [pathname]);

	const handleToggle = useCallback(() => {
		setOpen(prev => !prev);
	}, []);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);

	return (
		<>
			<NavItem
				item={data}
				depth={depth}
				open={open}
				active={active}
				externalLink={externalLink}
				onClick={handleToggle}
				config={config}
				handleClick={handleClick}
				selectedItem={selectedItem}
				hasChild={hasChild}
			/>

			{hasChild && (
				// <Collapse in={open} unmountOnExit>
					<NavSubList data={data.children} depth={depth} config={config} />
				// </Collapse>
			)}
		</>
	);
}

NavList.propTypes = {
	config: PropTypes.object,
	data: PropTypes.object,
	depth: PropTypes.number,
	hasChild: PropTypes.bool,
	handleClick: PropTypes.func,
	selectedItem: PropTypes.string,
};

// ----------------------------------------------------------------------

function NavSubList({ data, depth, config }) {
	return (
		<>
			{data.map(list => (
				<NavList
					key={list.title + list.path}
					data={list}
					depth={depth + 1}
					hasChild={!!list.children}
					config={config}
				/>
			))}
		</>
	);
}

NavSubList.propTypes = {
	config: PropTypes.object,
	data: PropTypes.array,
	depth: PropTypes.number,
};
